import React, { useEffect, useState } from "react";
import { Link } from "gatsby"

import { useMsal, useAccount } from "@azure/msal-react";
import { InteractionStatus, InteractionRequiredAuthError } from "@azure/msal-browser";
import { apiRequest } from "../authConfig";

import Layout from "../components/layout"
import Seo from "../components/seo"
import { JsonData } from "../components/jsonData";
import { getLogs } from "../utils/customApiCall";

import Paper from '@mui/material/Paper';

const LogsContent = () => {
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [graphData, setGraphData] = useState(null);

  useEffect(() => {
      if (account && !graphData && inProgress === InteractionStatus.None) {
          const request = {
              ...apiRequest,
              account: account
          };
          instance.acquireTokenSilent(request).then((response) => {
            window.localStorage.setItem("accessToken", JSON.stringify(response.accessToken));
              getLogs(response.accessToken).then(response => setGraphData(response));
          }).catch((e) => {
              if (e instanceof InteractionRequiredAuthError) {
                  instance.acquireTokenRedirect(request);
              }
          });
      }
  }, [account, inProgress, instance, graphData]);

  return (
      <Paper>
          { graphData ? <JsonData graphData={graphData} /> : null }
      </Paper>
  );
};

const LogsPage = () => (
  <Layout>
    <Seo title="Logs" />
    <h1>Logs</h1>
    <LogsContent />
    <Link to="/">Go back to the homepage</Link>
  </Layout>
)

export default LogsPage
