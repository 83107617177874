import { apiConfig } from "../authConfig";
import { getRequest, getJSONRequest, postRequest } from "./commonApiCalls";

export async function getReviews(accessToken) {
    return getJSONRequest(accessToken, `${apiConfig.apiEndpoint}/api/GetReviews`);
}

export async function getLogs(accessToken) {
    return getJSONRequest(accessToken, `${apiConfig.apiEndpoint}/api/GetAllLogs`);
}

export async function getReleaseNotes(accessToken) {
    return getJSONRequest(accessToken, `${apiConfig.apiEndpoint}/api/GetAllReleaseNotes`);
}

export async function getAppConfigSettings(accessToken) {
    return getJSONRequest(accessToken, `${apiConfig.apiEndpoint}/api/ListAppConfigSettings`);
}

export async function loginWebSockets(accessToken, userName) {
    return getJSONRequest(accessToken, `${apiConfig.apiEndpoint}/api/Login?userid=${userName}`);
}

export async function newSubscriber(accessToken, subscriptionBody) {
    return postRequest(accessToken, `${apiConfig.apiEndpoint}/api/NewSubscriber`, subscriptionBody);
}

export async function removeSubscriber(accessToken, subscriptionBody) {
    return postRequest(accessToken, `${apiConfig.apiEndpoint}/api/RemoveSubscriber`, subscriptionBody);
}

export async function sendNotification(accessToken, notificationBody) {
    return postRequest(accessToken, `${apiConfig.apiEndpoint}/api/SendNotification`, notificationBody);
}

export async function getBlogToken(accessToken, tokenType = "Blob") {
    return getRequest(accessToken, `${apiConfig.apiEndpoint}/api/BlogToken/${tokenType}`);
}

export async function invokeSearch(accessToken, searchBody) {
    return postRequest(accessToken, `${apiConfig.apiEndpoint}/api/search`, searchBody);
}

export async function getUserFeedback(accessToken, email) {
    return getRequest(accessToken, `${apiConfig.apiEndpoint}/api/GetUserFeedback/${email}`);
}

export async function addUserFeedback(accessToken, feedbackBody) {
    return postRequest(accessToken, `${apiConfig.apiEndpoint}/api/AddUserFeedback`, feedbackBody);
}

export async function updateUserFeedback(accessToken, feedbackBody) {
    return postRequest(accessToken, `${apiConfig.apiEndpoint}/api/UpdateUserFeedback`, feedbackBody);
}
